import { UPDATE_VEHICLEINFO, UPDATE_USER_INFO } from "./vuex/constants";
import store from "./vuex/index";
import env from "./utils/env";

// 获取app设备信息
function getAppInfo() {
  return new Promise<void>((resolve) => {
    if (window.ingeekJSApi?.common?.handleEvent) {
      window.ingeekJSApi.common.handleEvent?.(
        { url: "app://event/getSystemInfo" },
        (res: any) => {
          if (res?.data) {
            console.log(res.data);
            store.commit("UPDATE_ENV", res.data);
          }
          resolve();
        }
      );
    } else {
      resolve();
    }
  });
}

// 获取当前车辆信息
function getCurrentVehicleInfo() {
  return new Promise<void>((resolve) => {
    if (window.ingeekJSApi?.common?.handleEvent) {
      window.ingeekJSApi?.common?.handleEvent?.(
        { url: "app://event/getCurrentVehicleDetailInfo" },
        (res: any) => {
          if (res?.data) {
            console.log(res.data);
            store.commit(UPDATE_VEHICLEINFO, res.data);
          }
          resolve();
        }
      );
    } else {
      resolve();
    }
  });
}
// 获取app设备信息
function getUserInfo() {
  return new Promise<void>((resolve) => {
    if (window.ingeekJSApi?.common?.handleEvent) {
      window.ingeekJSApi.common.handleEvent?.(
        { url: "app://event/getUserInfo" },
        (res: any) => {
          console.log("userinfo", res.data);
          if (res?.data) {
            store.commit(UPDATE_USER_INFO, res.data);
          }
          resolve();
        }
      );
      setTimeout(() => {
        resolve();
      }, 120);
    } else {
      resolve();
    }
  });
}

async function intApp() {
  return new Promise<void>((resolve) => {
    if (window.ingeekJSApi) {
      window.ingeekJSApi.ready(async () => {
        try {
          await getAppInfo();
          await getCurrentVehicleInfo();
          await getUserInfo();
        } catch (err) {
          console.log(err);
        }
        resolve();
      });
    } else {
      if (env.isSVW) {
        window.webViewJavascriptBridge.callHandler(
          "getNavBarHeight",
          {},
          (err: any, data: any) => {
            store.commit(
              "UPDATE_ENV",
              Object.assign(store.state.env, data.height)
            );
            resolve(data);
          }
        );
      }
      resolve();
    }
  });
}
export { intApp };
