export const BASE_APP_NAV_VERSION = "3.0.0";
export const BASE_VW_APP_NAV_VERSION = "1.0.0";
const NOKEY_APP = "Nokey";
const VWA_APP = "VWA";
const SVW_APP = "SVW"; // 上汽大众app webview
const SVW_SDK = "VWSDK"; // 上汽大众乘趣SDK
const LEXUS_APP = "LexusSDK";
const BASE_VWA_APP_NAV_VERSION = "0.0.0";
const BASE_LEXUS_APP_NAV_VERSION = "0.0.0";
const nokeyRegex = new RegExp(
  `${NOKEY_APP}/\\d{1}\\.\\d{1,2}\\.\\d{1,2}`,
  "ig"
);
let regexType: any;
const vwaRegex = new RegExp(`${VWA_APP}/\\d{1}\\.\\d{1,2}\\.\\d{1,2}`, "ig");
const lexusRegex = new RegExp(
  `${LEXUS_APP}/\\d{1}\\.\\d{1,2}\\.\\d{1,2}`,
  "ig"
);

const userAgent = navigator.userAgent;
if (userAgent.includes(NOKEY_APP)) {
  regexType = nokeyRegex;
}
if (userAgent.includes(VWA_APP)) {
  regexType = vwaRegex;
}
if (userAgent.includes(LEXUS_APP)) {
  regexType = lexusRegex;
}
const uaResult = (userAgent.match(regexType)?.[0] as unknown as string)?.split(
  "/"
);
function getEnv() {
  const sdkType = uaResult?.[0];
  const matchResult = !!userAgent.match(regexType)?.[0];
  const isIOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  const isQq = userAgent.match(/\sqq/i) !== null;
  const isWx =
    /Weixin/gi.test(userAgent) ||
    /wechatdevtools/.test(userAgent) ||
    /MicroMessenger/.test(userAgent);
  const isAndroid =
    userAgent.indexOf("android") > -1 || userAgent.indexOf("adr") > -1;
  const isMiniProgram =
    userAgent.indexOf("miniprogram") > -1 ||
    userAgent.indexOf("miniProgram") > -1;
  let compareAppVersion = "";
  if (sdkType === NOKEY_APP) {
    compareAppVersion = BASE_APP_NAV_VERSION;
  }
  if (sdkType === VWA_APP) {
    compareAppVersion = BASE_VWA_APP_NAV_VERSION;
  }
  if (sdkType === LEXUS_APP) {
    compareAppVersion = BASE_LEXUS_APP_NAV_VERSION;
  }
  return {
    isAppWebView: !!matchResult,
    versionNum: uaResult?.[1] || "1.0.0",
    isVWA: userAgent.includes(VWA_APP),
    isSVW: userAgent.includes(SVW_APP),
    isSVWSDK: userAgent.includes(SVW_SDK),
    isNokey: userAgent.includes(NOKEY_APP),
    canUseWebRouter: compareVersion(uaResult?.[1], compareAppVersion),
    isIOS,
    isWx,
    isQq,
    isAndroid,
    isMiniProgram,
    appType: isIOS ? "IOS" : "ANDROID",
  };
}
export function compareVersion(
  appVerionOne: string,
  appVersionTwo: string
): boolean | Error {
  if (!appVerionOne || !appVersionTwo) return false;
  const verOneArr = appVerionOne.split(".");
  const verTwoArr = appVersionTwo.split(".");
  const maxLength = Math.max(verOneArr.length, verTwoArr.length);
  let i = 0;
  while (i < maxLength) {
    const v1Num = Number(verOneArr[i]);
    const v2Num = Number(verTwoArr[i]);
    i++;
    if (v1Num > v2Num) {
      return true;
    }
    if (v1Num < v2Num) {
      return false;
    }
    if (v1Num === v2Num) continue;
  }
  return true;
}

export default getEnv();
