import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import VideoManual from "../views/manual/VideoManual.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/unlock-explain",
    name: "unlockExplain",
    component: () => import("@/views/smartLock/unlockExplain.vue"),
  },
  {
    path: "/lock-explain",
    name: "lockExplain",
    component: () => import("@/views/smartLock/lockExplain.vue"),
  },
  {
    path: "/question-one",
    name: "questionOne",
    component: () => import("@/views/smartLock/questionOne.vue"),
  },
  {
    path: "/question-two",
    name: "questionTwo",
    component: () => import("@/views/smartLock/questionTwo.vue"),
  },
  {
    path: "/question-three",
    name: "questionThree",
    component: () => import("@/views/smartLock/questionThree.vue"),
  },
  //---------------------------------用户手册相关----------------------------------------
  {
    path: "/",
    name: "home",
    component: VideoManual,
    meta: {
      passStore: true,
    },
  },
  {
    // 用户手册（产品简介）
    path: "/appWebsite",
    name: "appWebsite",
    component: VideoManual,
  },
  {
    // 巴盾用户手册（产品简介）
    path: "/appWebsite_paton",
    name: "appWebsitePaton",
    component: () => import("@/views/manual/VideoManualPT.vue"),
  },
  {
    // 用户手册详情
    path: "/appWebsite/showVideo/:title?",
    name: "appWebsite-showVideo",
    meta: {
      passStore: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "VManualDetail" */ "@/views/manual/VManualDetail.vue"
      ),
  },
  {
    // 用户手册详情--巴盾
    path: "/appWebsite_paton/showVideo",
    name: "appWebsite_paton-showVideo",
    component: () =>
      import(
        /* webpackChunkName: "VManualDetail" */ "@/views/manual/VManualDetail.vue"
      ),
  },
  // 广丰小程序用户指南
  {
    path: "/gfMiniprogramUserManual",
    name: "gfMiniprogramUserManual",
    component: () =>
      import(
        /* webpackChunkName: "VManualDetailGF" */ "@/views/manual/VManualDetailGF.vue"
      ),
    meta: {
      cname: "广丰小程序用户手册",
    },
  },

  /**
   * 智能解闭锁用户手册（使用指南）
   * 新版智能锁地址，采用url区分不同的app
   */
  //
  {
    path: "/intelligent-unlocking/:app",
    name: "intelligentUnlockingWithApp",
    component: () =>
      import(
        /* webpackChunkName: "SmartLockerManual" */ "@/views/manual/SmartLockerManual.vue"
      ),
    meta: {
      cname: "智能解闭锁",
    },
    props: true,
  },
  {
    path: "/intelligent-unlockingVW",
    name: "intelligentUnlockingWithAppVW",
    component: () =>
      import(
        /* webpackChunkName: "SmartLockerManualVW" */ "@/views/manual/SmartLockerManualVW.vue"
      ),
    meta: {
      cname: "智能解闭锁",
    },
    props: true,
  },
  {
    path: "/intelligent-unlockingAVW",
    name: "intelligentUnlockingWithAppAVW",
    component: () =>
      import(
        /* webpackChunkName: "SmartLockerManualVW" */ "@/views/manual/SmartLockerManualAVW.vue"
      ),
    meta: {
      cname: "智能解闭锁",
    },
    props: true,
  },
  // 智能解闭锁用户手册（使用指南）- 老版本，为了向前兼容
  {
    path: "/intelligent-unlocking",
    name: "intelligentUnlocking",
    component: () =>
      import(
        /* webpackChunkName: "SmartLockerManual" */ "@/views/manual/SmartLockerManual.vue"
      ),
    meta: {
      cname: "智能解闭锁",
    },
  },
  // 新手说明
  {
    path: "/novice-instructions",
    name: "noviceInstructions",
    component: () =>
      import(
        /* webpackChunkName: "NormalManual" */ "@/views/manual/NormalManual.vue"
      ),
    meta: {
      cname: "新手说明",
    },
  },
  // 新手说明-巴盾
  {
    path: "/novice-instructions/:app",
    name: "noviceInstructionsNew",
    component: () =>
      import(
        /* webpackChunkName: "NormalManual" */ "@/views/manual/NormalManual.vue"
      ),
    meta: {
      cname: "新手说明巴盾",
    },
    props: true,
  },
  // 新手说明详情
  {
    path: "/novice-instructions/:app/detail",
    name: "noviceInstructionsDetail",
    component: () =>
      import(
        /* webpackChunkName: "Manual" */ "@/views/manual/ManualDetail.vue"
      ),
    meta: {
      cname: "新手说明详情",
    },
    props: true,
  },

  //-------------------------------------车型列表------------------------------------

  // 支持车型列表（乘趣）
  {
    path: "/carType",
    name: "carType",
    component: () =>
      import(/* webpackChunkName: "CarType" */ "@/views/carType/CarType.vue"),
    meta: {
      cname: "车型",
    },
  },
  // 支持车型列表（巴盾）
  {
    path: "/carType_paton",
    name: "carTypePaton",
    component: () =>
      import(/* webpackChunkName: "CarType" */ "@/views/carType/CarType.vue"),
    meta: {
      cname: "车型",
    },
  },

  //----------------------------------------车模---------------------------------
  // 车模
  {
    path: "/carModel",
    name: "carModel",
    component: () =>
      import(
        /* webpackChunkName: "CarModel" */ "@/views/carModel/CarModel.vue"
      ),
    meta: {
      cname: "车模型",
    },
  },
  //------------------------------------------意见反馈-------------------------------
  {
    path: "/feedback",
    name: "feedBack",
    component: () =>
      import(
        /* webpackChunkName: "Feedback" */ "@/views/feedback/FeedBack.vue"
      ),
    meta: {
      cname: "意见反馈",
    },
  },
  {
    path: "/feedback/success",
    name: "feedback/success",
    component: () =>
      import(
        /* webpackChunkName: "FeedbackSuccess" */ "@/views/feedback/FeedBackSuccess.vue"
      ),
    meta: {
      cname: "意见反馈",
    },
  },
  {
    path: "/feedback_paton",
    name: "feedbackPaton",
    component: () =>
      import(
        /* webpackChunkName: "Feedback" */ "@/views/feedback/FeedBack.vue"
      ),
    meta: {
      cname: "意见反馈",
    },
  },
  {
    path: "/feedback_paton/success",
    name: "feedback_paton/success",
    component: () =>
      import(
        /* webpackChunkName: "FeedbackSuccess" */ "@/views/feedback/FeedBackSuccess.vue"
      ),
    meta: {
      cname: "意见反馈",
    },
  },

  //-------------------------------------活动页面------------------------------------
  {
    // 活动页面
    path: "/purchaseVW",
    name: "purchaseVW",
    component: () =>
      import(
        /* webpackChunkName: "purchaseVW" */ "@/views/activities/purchaseVW.vue"
      ),
  },
  {
    // 活动页面
    path: "/digitalKeyActivities",
    name: "digitalKeyActivities",
    component: () =>
      import(
        /* webpackChunkName: "Activities" */ "@/views/activities/Activities.vue"
      ),
  },
  {
    // 活动页面
    path: "/goodsDetail",
    name: "goodsDetail",
    component: () =>
      import(
        /* webpackChunkName: "goodsDetail" */ "@/views/activities/GoodsDetail.vue"
      ),
  },

  //-------------------------------------用户协议------------------------------------
  {
    // 大众停服通知
    path: "/userInformVW",
    name: "userVWuserInformVWInform",
    component: () =>
      import(
        /* webpackChunkName: "userInformVW" */ "@/views/agreements/vwNotify.vue"
      ),
  },
  {
    // 大众功能迁移
    path: "/migrationVW",
    name: "migrationVW",
    component: () =>
      import(
        /* webpackChunkName: "migrationVW" */ "@/views/customerService/migrationVW.vue"
      ),
  },
  {
    // SDK内部隐私协议
    path: "/paLimit_sdk",
    name: "paLimit_sdk",
    component: () =>
      import(
        /* webpackChunkName: "migrationVW" */ "@/views/privacy/SdkPrivacy.vue"
      ),
  },
  {
    // 用户服务协议
    path: "/paRegist",
    name: "paRegist",
    component: () =>
      import(
        /* webpackChunkName: "Agreements" */ "@/views/agreements/Agreements.vue"
      ),
  },
  {
    // 海外用户服务协议
    path: "/paRegist_overseas",
    name: "paRegist_overseas",
    component: () =>
      import(
        /* webpackChunkName: "paRegist_oversea" */ "@/views/agreements/AgreementsOversea.vue"
      ),
  },
  {
    // 用户服务协议,悠悠车主，对一丰项目支持的页面
    path: "/paRegist_yycz",
    name: "paRegist_yycz",
    component: () =>
      import(
        /* webpackChunkName: "AgreementsYY" */ "@/views/agreements/AgreementsYY.vue"
      ),
  },
  {
    // 用户服务协议-巴盾数钥
    path: "/paRegist_paton",
    name: "paRegist_paton",
    component: () =>
      import(
        /* webpackChunkName: "AgreementsPT" */ "@/views/agreements/AgreementsPT.vue"
      ),
  },
  {
    // 用户服务协议广丰
    path: "/serviceAgreementGf",
    name: "serviceAgreementGf",
    component: () =>
      import(
        /* webpackChunkName: "AgreementsGF" */ "@/views/agreements/AgreementsGF.vue"
      ),
  },
  // 门店试用协议
  {
    path: "/userTrialAgreement",
    name: "userTrialAgreement",
    component: () =>
      import(
        /* webpackChunkName: "AgreementsMD" */ "@/views/agreements/AgreementsMD.vue"
      ),
    meta: {
      cname: "用户试用协议",
    },
  },
  // 我的车钥匙小程序用户协议
  {
    path: "/keyprotocol-user",
    name: "keyprotocolUser",
    component: () =>
      import(
        /* webpackChunkName: "AgreementsMK" */ "@/views/agreements/AgreementsMK.vue"
      ),
    meta: {
      cname: "用户服务协议",
    },
  },

  //-------------------------------------隐私协议------------------------------------

  {
    // 隐私权保护政策
    path: "/paLimit",
    name: "paLimit",
    component: () =>
      import(/* webpackChunkName: "Privacy" */ "@/views/privacy/Privacy.vue"),
  },
  {
    // 隐私权保护政策-海外
    path: "/paLimit_overseas",
    name: "paLimit_overseas",
    component: () =>
      import(
        /* paLimit_overseas: "PrivacyOverseas" */ "@/views/privacy/PrivacyOverseas.vue"
      ),
  },
  {
    // 隐私权保护政策, 悠悠车主，对一丰项目支持的页面
    path: "/paLimit_yycz",
    name: "paLimit_yycz",
    component: () =>
      import(
        /* webpackChunkName: "PrivacyYY" */ "@/views/privacy/PrivacyYY.vue"
      ),
  },
  {
    // 隐私权保护政策--巴盾数钥
    path: "/paLimit_paton",
    name: "paLimit_paton",
    component: () =>
      import(
        /* webpackChunkName: "PrivacyPT" */ "@/views/privacy/PrivacyPT.vue"
      ),
  },
  {
    // 隐私权保护政策--广丰
    path: "/privacyPolicyGf",
    name: "privacyPolicyGf",
    component: () =>
      import(
        /* webpackChunkName: "PrivacyGF" */ "@/views/privacy/PrivacyGF.vue"
      ),
  },
  {
    // 隐私权保护协议-我的车钥匙
    path: "/keyprotocol-pricacy",
    name: "keyprotocolPricacy",
    component: () =>
      import(
        /* webpackChunkName: "PrivacyMK" */ "@/views/privacy/PrivacyMK.vue"
      ),
  },

  //--------------------------------------保养-----------------------------------
  {
    // 车辆保养
    path: "/maintenance",
    name: "maintenance",
    component: () =>
      import(
        /* webpackChunkName: "MainTenance" */ "@/views/maintenance/MainTenance.vue"
      ),
    meta: {
      cname: "车辆保养",
    },
  },
  {
    // 车辆保养-巴盾
    path: "/maintenance_paton",
    name: "maintenancePaton",
    component: () =>
      import(
        /* webpackChunkName: "MaintenancePT" */ "@/views/maintenance/MaintenancePT.vue"
      ),
    meta: {
      cname: "车辆保养",
    },
  },

  //---------------------------------------客户服务（常见问题）----------------------------------
  {
    // 客户服务
    path: "/customerService",
    name: "customerService",
    component: () =>
      import(
        /* webpackChunkName: "CustomerService" */ "@/views/customerService/CustomerService.vue"
      ),
    meta: {
      cname: "客户服务",
    },
  },
  {
    // 常见问题
    path: "/commonProblems",
    name: "commonProblems",
    component: () =>
      import(
        /* webpackChunkName: "CustomerService" */ "@/views/customerService/CustomerServicev2.vue"
      ),
    meta: {
      cname: "帮助中心",
    },
  },
  {
    // 海外常见问题
    path: "/tika/commonProblems",
    name: "tikaCommonProblems",
    component: () =>
      import(
        /* webpackChunkName: "tikaCommonProblems" */ "@/views/customerService/CustomerServicevTika.vue"
      ),
    meta: {
      cname: "帮助中心",
    },
  },
  {
    // 常见问题
    path: "/allProblems/:id?",
    name: "allProblems",
    component: () =>
      import(
        /* webpackChunkName: "allProblems" */ "@/views/customerService/AllQuestion.vue"
      ),
    meta: {
      cname: "全部问题",
    },
  },
  {
    // 大众常见问题
    path: "/allProblemsVW/:id?",
    name: "allProblemsVW",
    component: () =>
      import(
        /* webpackChunkName: "allProblemsVW" */ "@/views/customerService/AllQuestionVW.vue"
      ),
    meta: {
      cname: "全部问题",
    },
  },
  {
    // 大众安徽常见问题
    path: "/allProblemsAVW/:id?",
    name: "allProblemsAVW",
    component: () =>
      import(
        /* webpackChunkName: "allProblemsVW" */ "@/views/customerService/AllQuestionAVW.vue"
      ),
    meta: {
      cname: "全部问题",
    },
  },
  {
    // 常见问题
    path: "/searchQuestion",
    name: "searchQuestion",
    component: () =>
      import(
        /* webpackChunkName: "searchQuestion" */ "@/views/customerService/SearchQuestion.vue"
      ),
    meta: {
      cname: "帮助中心",
    },
  },
  {
    // 搜索问题
    path: "/searchQuestionVW",
    name: "searchQuestionVW",
    component: () =>
      import(
        /* webpackChunkName: "searchQuestionVW" */ "@/views/customerService/SearchQuestionVW.vue"
      ),
    meta: {
      cname: "帮助中心",
    },
  },
  {
    // 大众常见问题
    path: "/commonProblemsVW",
    name: "commonProblemsVW",
    component: () =>
      import(
        /* webpackChunkName: "CustomerServiceVW" */ "@/views/customerService/CustomerServiceVW.vue"
      ),
  },
  {
    // 大众常见问题v2
    path: "/commonProblemsVWV2",
    name: "commonProblemsVWV2",
    component: () =>
      import(
        /* webpackChunkName: "CustomerServiceVWV2" */ "@/views/customerService/CustomerServiceVWV2.vue"
      ),
  },
  {
    // 大众安徽常见问题
    path: "/commonProblemsAVW",
    name: "commonProblemsAVW",
    component: () =>
      import(
        /* webpackChunkName: "CustomerServiceAVW" */ "@/views/customerService/CustomerServiceAVW.vue"
      ),
  },
  {
    // 小程序 客户服务
    path: "/customerServiceMini",
    name: "customerServiceMini",
    component: () =>
      import(
        /* webpackChunkName: "CustomerServiceMini" */ "@/views/customerService/CustomerServiceMini.vue"
      ),
    meta: {
      cname: "小程序客户服务",
    },
  },
  // 常见问题--巴盾数钥
  {
    path: "/commonProblems_paton",
    name: "commonProblemsPaton",
    component: () =>
      import(
        /* webpackChunkName: "CustomerServicePT" */ "@/views/customerService/CustomerServicePT.vue"
      ),
    meta: {
      cname: "帮助中心",
    },
  },
  // 常见问题--巴盾lite
  {
    path: "/commonProblems_paton_lite",
    name: "commonProblemsPatonLite",
    component: () =>
      import(
        /* webpackChunkName: "CustomerServicePTLite" */ "@/views/customerService/CustomerServicePTLite.vue"
      ),
    meta: {
      cname: "帮助中心",
    },
  },
  {
    // 问题详情
    path: "/commonProblems/detail",
    name: "commonProblemsDetailv2",
    component: () =>
      import(
        /* webpackChunkName: "CustomerServiceDetail" */ "@/views/customerService/Detailv2.vue"
      ),
    meta: {
      cname: "问题详情",
    },
  },
  {
    // LEXUS问题详情
    path: "/lexus/commonProblems",
    name: "lexusCommonProblems",
    component: () =>
      import(
        /* webpackChunkName: "lexusCommonProblems" */ "@/views/customerService/lexus/HelpCenter.vue"
      ),
    meta: {
      cname: "问题详情",
    },
  },
  {
    // LEXUS问题详情
    path: "/lexus/commonProblems/detail",
    name: "lexusCommonProblemsDetail",
    component: () =>
      import(
        /* webpackChunkName: "lexusCommonProblemsDetail" */ "@/views/customerService/lexus/QuestionDetail.vue"
      ),
    meta: {
      cname: "问题详情",
    },
  },
  {
    // LEXUS用户协议
    path: "/lexus/paRegist",
    name: "lexusPaRegist",
    component: () =>
      import(
        /* webpackChunkName: "lexusPaRegist" */ "@/views/agreements/lexus/Agreements.vue"
      ),
    meta: {
      cname: "问题详情",
    },
  },
  {
    // 大众问题详情
    path: "/commonProblemsVWV2/detailVW",
    name: "commonProblemsDetailVWV2",
    component: () =>
      import(
        /* webpackChunkName: "commonProblemsDetailVWV2" */ "@/views/customerService/DetailVW.vue"
      ),
    meta: {
      cname: "问题详情",
    },
  },
  {
    // 大众问题详情
    path: "/commonProblemsAVW/detail",
    name: "commonProblemsDetailDAVW",
    component: () =>
      import(
        /* webpackChunkName: "commonProblemsDetailDAVW" */ "@/views/customerService/DetailAVW.vue"
      ),
    meta: {
      cname: "问题详情",
    },
  },
  {
    // 个人信息收集清单
    path: "/personCollect",
    name: "personCollect",
    component: () =>
      import(
        /* webpackChunkName: "personCollect" */ "@/views/agreements/PersonCollect.vue"
      ),
    meta: {
      cname: "个人信息收集清单",
    },
  },
  {
    // 个人信息收集清单
    path: "/commonShare",
    name: "commonShare",
    component: () =>
      import(
        /* webpackChunkName: "commonShare" */ "@/views/privacy/CommonShare.vue"
      ),
    meta: {
      cname: "第三方信息共享清单",
    },
  },
  {
    // 问题详情
    path: "/commonProblems/old_detail",
    name: "oldCommonProblemsDetail",
    component: () =>
      import(
        /* webpackChunkName: "CustomerServiceDetail" */ "@/views/customerService/Detail.vue"
      ),
    meta: {
      cname: "问题详情",
    },
  },
  {
    // 功能更新
    path: "/funcList",
    name: "funcList",
    component: () =>
      import(
        /* webpackChunkName: "funcList" */ "@/views/functionList/index.vue"
      ),
    meta: {
      cname: "功能更新",
    },
  },
  {
    // 功能更新
    path: "/funcListVW/moduleFunDetailVW",
    name: "moduleFunDetailVW",
    component: () =>
      import(
        /* webpackChunkName: "moduleFunDetailVW" */ "@/views/functionList/BeginnerVW/Detail.vue"
      ),
    meta: {
      cname: "功能更新",
    },
  },
  {
    // 功能详情页
    path: "/funcList/moduleFunDetail/:id",
    name: "moduleFunDetail",
    component: () =>
      import(
        /* webpackChunkName: "funcList" */ "@/views/functionList/moduleFunDetail.vue"
      ),
    meta: {
      hideNavBar: 1,
      cname: "功能详情",
    },
  },
  {
    // 加入我们
    path: "/joinUs",
    name: "joinUs",
    component: () =>
      import(/* webpackChunkName: "JoinUs" */ "@/views/joinUs/JoinUs.vue"),
    meta: {
      cname: "加入我们",
    },
  },
  {
    // 本田数字钥匙安装说明书
    path: "/honda/ins",
    name: "hondaIns",
    component: () =>
      import(
        /* webpackChunkName: "hondaIns" */ "@/views/brandBuilding/honda/instruction/InstallBook.vue"
      ),
    meta: {
      cname: "本田数字钥匙安装指导",
    },
  },
  {
    // 本田数字钥匙安装说明书列表
    path: "/honda/ins/list",
    name: "hondaInsList",
    component: () =>
      import(
        /* webpackChunkName: "hondaInsList" */ "@/views/brandBuilding/honda/instruction/BookList.vue"
      ),
    meta: {
      cname: "安装指导",
    },
  },
  {
    // 本田数字钥匙安装视频说明书
    path: "/honda/ins/video",
    name: "hondaInsVideo",
    component: () =>
      import(
        /* webpackChunkName: "hondaInsVideo" */ "@/views/brandBuilding/honda/instruction/BookVideo.vue"
      ),
    meta: {
      cname: "安装指导",
    },
  },
  {
    // 本田数字钥匙安装视频说明书
    path: "/honda/ins/pdf",
    name: "hondaInsPdf",
    component: () =>
      import(
        /* webpackChunkName: "hondaInsPdf" */ "@/views/brandBuilding/honda/instruction/BookPdf.vue"
      ),
    meta: {
      cname: "本田数字钥匙安装视频说明书",
    },
  },
  {
    // 本田数字钥匙安装说明书
    path: "/lexus/ins",
    name: "lexusIns",
    component: () =>
      import(
        /* webpackChunkName: "lexusIns" */ "@/views/brandBuilding/lexus/instruction/InstallBook.vue"
      ),
    meta: {
      cname: "雷克萨斯数字钥匙安装指导",
    },
  },
  {
    // 本田数字钥匙安装说明书列表
    path: "/lexus/ins/list",
    name: "lexusInsList",
    component: () =>
      import(
        /* webpackChunkName: "lexusInsList" */ "@/views/brandBuilding/lexus/instruction/BookList.vue"
      ),
    meta: {
      cname: "雷克萨斯安装指导",
    },
  },
  {
    // 本田数字钥匙安装视频说明书
    path: "/lexus/ins/video",
    name: "lexusInsVideo",
    component: () =>
      import(
        /* webpackChunkName: "lexusInsVideo" */ "@/views/brandBuilding/lexus/instruction/BookVideo.vue"
      ),
    meta: {
      cname: "雷克萨斯安装指导",
    },
  },
  {
    // 本田数字钥匙安装视频说明书
    path: "/lexus/ins/pdf",
    name: "lexusInsPdf",
    component: () =>
      import(
        /* webpackChunkName: "lexusInsPdf" */ "@/views/brandBuilding/lexus/instruction/BookPdf.vue"
      ),
    meta: {
      cname: "雷克萨斯数字钥匙安装视频说明书",
    },
  },
  {
    // 本田宣发数字钥匙安装说明书
    path: "/hondaDis/ins",
    name: "hondaDisIns",
    component: () =>
      import(
        /* webpackChunkName: "hondaDisIns" */ "@/views/brandBuilding/honda/dispersion/InstallBook.vue"
      ),
    meta: {
      cname: "本田数字钥匙安装指导",
    },
  },
  {
    // 本田数字钥匙安装说明书列表
    path: "/hondaDis/ins/list",
    name: "hondaDisInsList",
    component: () =>
      import(
        /* webpackChunkName: "hondaDisInsList" */ "@/views/brandBuilding/honda/dispersion/BookList.vue"
      ),
    meta: {
      cname: "本田数字钥匙安装指导",
    },
  },
  {
    // 本田宣发钥匙安装视频说明书
    path: "/hondaDis/ins/video",
    name: "hondaDisInsVideo",
    component: () =>
      import(
        /* webpackChunkName: "hondaDisInsVideo" */ "@/views/brandBuilding/honda/dispersion/BookVideo.vue"
      ),
    meta: {
      cname: "本田数字钥匙安装指导",
    },
  },
  {
    // 本田宣发钥匙安装视频说明书
    path: "/hondaDis/ins/pdf",
    name: "hondaDisInsPdf",
    component: () =>
      import(
        /* webpackChunkName: "hondaDisInsPdf" */ "@/views/brandBuilding/honda/dispersion/BookPdf.vue"
      ),
    meta: {
      cname: "本田数字钥匙安装指导",
    },
  },
  {
    // 本田宣发钥匙安装视频说明书
    path: "/tika/share",
    name: "tikaShare",
    component: () =>
      import(
        /* webpackChunkName: "tikaShare" */ "@/views/activities/sharekey.vue"
      ),
    meta: {
      cname: "钥匙分享",
    },
  },
  {
    path: "/activity/report-2024",
    name: "nativejs",
    component: () =>
      import(
        /* webpackChunkName: "repoer2024" */ "@/views/activities/2024-sum.vue"
      ),
    meta: {
      cname: "年报",
    },
  },
  {
    path: "/vw/guide",
    name: "vwGuide",
    component: () =>
      import(/* webpackChunkName: "vwGuide" */ "@/views/activities/guide.vue"),
    meta: {
      cname: "联调native",
    },
  },
  {
    path: "/activity/year-report",
    name: "yearReport",
    component: () =>
      import(
        /* webpackChunkName: "yearReport" */ "@/views/agreements/YearPort.vue"
      ),
    meta: {
      cname: "年报说明",
    },
  },
  {
    path: "/activity/nfc-intro",
    name: "nfcIntro",
    component: () =>
      import(
        /* webpackChunkName: "nfcIntro" */ "@/views/activities/NFCIntro.vue"
      ),
    meta: {
      cname: "nfc介绍",
    },
  },
  {
    path: "/activity/month-report",
    name: "monthReport",
    component: () =>
      import(
        /* webpackChunkName: "monthReport" */ "@/views/activities/monthSum/index.vue"
      ),
    meta: {
      cname: "联调native",
    },
  },
  {
    path: "/lx/paRegist",
    name: "nfcIntro",
    component: () =>
      import(
        /* webpackChunkName: "nfcIntro" */ "@/views/agreements/lx/Agreements.vue"
      ),
    meta: {
      cname: "灵犀用户协议",
    },
  },

  {
    path: "/lx/paRegist",
    name: "lxPaRegist",
    component: () =>
      import(
        /* webpackChunkName: "nfcIntro" */ "@/views/agreements/lx/Agreements.vue"
      ),
    meta: {
      cname: "nfc介绍",
    },
  },
  {
    path: "/lx/paLimit",
    name: "lxPaLimit",
    component: () =>
      import(
        /* webpackChunkName: "nfcIntro" */ "@/views/privacy/lx/Privacy.vue"
      ),
    meta: {
      cname: "nfc介绍",
    },
  },
  {
    path: "/lx/commonShare",
    name: "lxCommonShare",
    component: () =>
      import(
        /* webpackChunkName: "lxCommonShare" */ "@/views/privacy/lx/CommonShare.vue"
      ),
    meta: {
      cname: "第三方信息共享清单",
    },
  },
  {
    path: "/lx/personCollect",
    name: "lxPersonCollect",
    component: () =>
      import(
        /* webpackChunkName: "lxPersonCollect" */ "@/views/agreements/lx/PersonCollect.vue"
      ),
    meta: {
      cname: "个人信息收集清单",
    },
  },
  {
    path: "/cq/pure-coupon",
    name: "cqPureConpo",
    component: () =>
      import(
        /* webpackChunkName: "cqPureConpo" */ "@/views/activities/cq/pure-coupon.vue"
      ),
    meta: {
      cname: "清明活动",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
